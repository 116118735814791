var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-table", {
        staticClass: "action-table",
        attrs: {
          items: _vm.items,
          fields: _vm.allFields,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filter,
          "filter-included-fields": _vm.filterOn,
          "filter-function": _vm.filterTable,
          hover: "",
          responsive: "",
          "sticky-header": "",
          "table-class": "text-nowrap performance-table",
          "tbody-tr-class": "performance-table-row",
        },
        on: { filtered: _vm.onFiltered },
        scopedSlots: _vm._u(
          [
            _vm.fieldGroup.length > 0
              ? {
                  key: "thead-top",
                  fn: function () {
                    return [
                      _c(
                        "b-tr",
                        _vm._l(_vm.fieldGroup, function (th, index) {
                          return _c(
                            "b-th",
                            {
                              key: `th-${index}`,
                              class: { "th-bg-theme-secondary": th.show },
                              attrs: { colspan: th.items },
                            },
                            [
                              _c("span", { class: { "sr-only": !th.show } }, [
                                _vm._v(_vm._s(th.title)),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.hasActions
              ? {
                  key: "cell(actions)",
                  fn: function (row) {
                    return [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hasUnblockAction && row.item.active != 1,
                            expression:
                              "hasUnblockAction && row.item.active != 1",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: `Unblock ${_vm.setTooltipText(
                                row.item
                              )}`,
                              placement: "bottom-center",
                              classes: ["light"],
                              delay: {
                                show: 500,
                                hide: 300,
                              },
                              offset: "5",
                            },
                            expression:
                              "{\n          content: `Unblock ${setTooltipText(row.item)}`,\n          placement: 'bottom-center',\n          classes: ['light'],\n          delay: {\n            show: 500,\n            hide: 300\n          },\n          offset: '5'\n        }",
                          },
                        ],
                        staticClass:
                          "simple-icon-user-follow text-primary font-weight-bold",
                        on: {
                          click: function ($event) {
                            return _vm.onItemUnblocked(row.item)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hasUnblockAction && row.item.active == 1,
                            expression:
                              "hasUnblockAction && row.item.active == 1",
                          },
                        ],
                        staticClass: "pl-2 pr-2",
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: `Edit ${_vm.setTooltipText(row.item)}`,
                              placement: "bottom-center",
                              classes: ["light"],
                              delay: {
                                show: 500,
                                hide: 300,
                              },
                              offset: "5",
                            },
                            expression:
                              "{\n          content: `Edit ${setTooltipText(row.item)}`,\n          placement: 'bottom-center',\n          classes: ['light'],\n          delay: {\n            show: 500,\n            hide: 300\n          },\n          offset: '5'\n        }",
                          },
                        ],
                        staticClass:
                          "simple-icon-note ml-2 text-primary font-weight-bold",
                        on: {
                          click: function ($event) {
                            return _vm.onItemClick(row.item)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: `Delete ${_vm.setTooltipText(row.item)}`,
                              placement: "bottom-center",
                              classes: ["danger"],
                              delay: {
                                show: 500,
                                hide: 300,
                              },
                              offset: "5",
                            },
                            expression:
                              "{\n          content: `Delete ${setTooltipText(row.item)}`,\n          placement: 'bottom-center',\n          classes: ['danger'],\n          delay: {\n            show: 500,\n            hide: 300\n          },\n          offset: '5'\n        }",
                          },
                        ],
                        staticClass:
                          "simple-icon-trash ml-2 text-danger font-weight-bold",
                        on: {
                          click: function ($event) {
                            return _vm.onItemDelete(row.item)
                          },
                        },
                      }),
                    ]
                  },
                }
              : null,
            {
              key: "cell(avatar)",
              fn: function (data) {
                return [
                  _c("b-avatar", {
                    attrs: {
                      size: "2em",
                      src: data.item.avatar,
                      text: _vm.getNameInitials(
                        data.item.full_name || data.item.name
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(data.item.full_name || data.item.name)),
                  ]),
                ]
              },
            },
            {
              key: "top-row",
              fn: function ({ columns }) {
                return _vm.rowGroup
                  ? [
                      _c("td", { attrs: { colspan: columns } }, [
                        _vm._v(_vm._s(_vm.items[0][_vm.rowGroup])),
                      ]),
                    ]
                  : undefined
              },
            },
            {
              key: "row-details",
              fn: function ({ item }) {
                return _vm.rowGroup && _vm.nextRowGroup
                  ? [
                      _vm._v(
                        "\n      " + _vm._s(item[_vm.nextRowGroup]) + "\n    "
                      ),
                    ]
                  : undefined
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "my-1 mx-auto", attrs: { sm: "7", md: "6" } },
            [
              _c("b-pagination", {
                staticClass: "my-0",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "center",
                  size: "sm",
                },
                on: { change: _vm.onPageChange },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }